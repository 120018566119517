import React, { FC } from "react";
import { OfferTemplate } from "@templates/OfferTemplate";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { graphql } from "gatsby";
import { TwoColumnsSection } from "@components/atoms/TwoColumnsSection";
import { UniversalSection } from "@components/molecules/UniversalSection";
import styled from "styled-components";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { breakpoints } from "@theme/breakpoints";
import HtmlParser from "react-html-parser";
import { stylesWrapper } from "@theme/commonStyles";
import { Routes } from "@routes";
import WhatWeCanDoMore from "@components/organisms/WhatWeCanDoMore";

const VisualizationsInterior: FC<Props> = ({ data }) => {
  const { t } = useTranslation("offer");
  const { t: ct } = useTranslation("common");

  return (
    <OfferTemplate
      title={ct("title-offer-visualizations-interior")}
      description={ct("description-offer-visualizations-interior")}
      heroTitle={t("visualizations-interior.hero.title")}
      heroDescription={t("visualizations-interior.hero.description", {
        returnObjects: true,
      })}
      heroImage={data.heroImage.childImageSharp.gatsbyImageData}
    >
      <UniversalSection
        title={t("visualizations-interior.sections.0.title")}
        description={t("visualizations-interior.sections.0.description", {
          returnObjects: true,
        })}
        images={data.kitchenetteGallery.nodes.map(
          (image) => image.childImageSharp.gatsbyImageData
        )}
        galleryId={0}
        fromRight
      />

      <UniversalSection
        title={t("visualizations-interior.sections.1.title")}
        description={t("visualizations-interior.sections.1.description", {
          returnObjects: true,
        })}
        images={data.livingRoomGallery.nodes.map(
          (image) => image.childImageSharp.gatsbyImageData
        )}
        galleryId={1}
      />

      <UniversalSection
        title={t("visualizations-interior.sections.2.title")}
        description={t("visualizations-interior.sections.2.description", {
          returnObjects: true,
        })}
        images={data.bathroomGallery.nodes.map(
          (image) => image.childImageSharp.gatsbyImageData
        )}
        btnText={t("see-more")}
        btnTo={Routes.MarketingCampaigns}
        btnType="unframed"
        galleryId={2}
        fromRight
      />

      <UniversalSection
        title={t("visualizations-interior.sections.3.title")}
        description={t("visualizations-interior.sections.3.description", {
          returnObjects: true,
        })}
        images={data.mezzanineGallery.nodes.map(
          (image) => image.childImageSharp.gatsbyImageData
        )}
        galleryId={3}
      />

      <UniversalSection
        title={t("visualizations-interior.sections.4.title")}
        description={t("visualizations-interior.sections.4.description", {
          returnObjects: true,
        })}
        images={data.childrenRoomGallery.nodes.map(
          (image) => image.childImageSharp.gatsbyImageData
        )}
        galleryId={4}
        fromRight
      />

      <UniversalSection
        title={t("visualizations-interior.sections.5.title")}
        description={t("visualizations-interior.sections.5.description", {
          returnObjects: true,
        })}
        video="https://cegielniana-rend-tour.vercel.app/?zoomOnlyWithCtrlKey=true"
      />

      <WhatWeCanDoMore exclude={4} />
    </OfferTemplate>
  );
};

interface Props {
  data: {
    heroImage: ChildImageSharp;
    kitchenetteGallery: {
      nodes: ChildImageSharp[];
    };
    livingRoomGallery: {
      nodes: ChildImageSharp[];
    };
    bathroomGallery: {
      nodes: ChildImageSharp[];
    };
    mezzanineGallery: {
      nodes: ChildImageSharp[];
    };
    childrenRoomGallery: {
      nodes: ChildImageSharp[];
    };
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    heroImage: file(name: { eq: "interior-visualization_card" }) {
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
    }

    kitchenetteGallery: allFile(
      filter: { name: { regex: "/interior-visualization-kitchenette_/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }

    livingRoomGallery: allFile(
      filter: { name: { regex: "/interior-visualization-living-room_/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }

    bathroomGallery: allFile(
      filter: { name: { regex: "/interior-visualization-bathroom_/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }

    mezzanineGallery: allFile(
      filter: { name: { regex: "/interior-visualization-mezzanine_/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }

    childrenRoomGallery: allFile(
      filter: { name: { regex: "/interior-visualization-children-room_/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  }
`;

export default VisualizationsInterior;
